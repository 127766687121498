import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { createRichTextSerializer } from '../utils/prismic'
import PageHeader from '../components/PageHeader'

export const query = graphql`
  query {
    prismic {
      cookie_policy(uid: "cookie-policy", lang: "en-us") {
        title
        description
        text
      }
    }
  }
`

const bodySerializer = createRichTextSerializer({
  paragraphClassname: () => 'mb-4',
  ulClassname: () => 'mb-4',
  heading2Classname: () => 'mt-8 mb-4',
})

const IndexPage = ({ data }) => {
  const { cookie_policy: cookiePolicy } = data.prismic
  return (
    <Layout>
      <SEO title={cookiePolicy.title} keywords={[`longevity`]} />

      <PageHeader
        titles={[cookiePolicy.title]}
        richDescription={cookiePolicy.description}
      />

      <div className="container lg:max-w-xl px-8 lg:px-0 mt-16 mb-24 lg:mb-32 mx-auto font-serif text-blue-dianne leading-tight font-normal">
        <article className="copy-2 measure break-words">
          {RichText.render(cookiePolicy.text, undefined, bodySerializer)}
        </article>
      </div>
    </Layout>
  )
}

export default IndexPage
